import React, {useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react';

function App() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        setInterval(() => {
            fetch(`${window.location.protocol}//${process.env.REACT_APP_BACKEND_HOST}`)
                .then(r => r.json())
                .then(r => r.Items.filter((i: { topic: string; }) => i.topic === "smoker/control"))
                .then(r => {
                    console.log(r);
                    setItems(r);
                })
            ;
        }, 10000);
    }, []);

    return (
        <ReactECharts option={{
            dataset: {
                dimensions: ['aws_timestamp', 'smokerTemp', "d", "i", "p"],
                source: items
            },
            xAxis: {
                type: 'value',
                min: function (value: { min: number; }) {
                    return value.min - 20;
                }
            },
            yAxis: {
                type: 'value',
                min: function (value: { min: number; }) {
                    return value.min - 0.1;
                },
                max: function (value: { max: number; }) {
                    return value.max + 0.1;
                }
            },
            series: [
                {
                    type: 'line',
                    symbol: 'none',
                    encode: {
                        // Map "amount" column to x-axis.
                        x: 'aws_timestamp',
                        // Map "product" row to y-axis.
                        y: 'smokerTemp'
                    }
                },
            ]
        }}
        />
    );
}

export default App;
